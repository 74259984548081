// Font stacks
$font-stack-main: 'Taz', Arial, sans-serif;
$font-stack-alt: 'Creighton Pro', Arial, sans-serif;
$font-stack-mono: 'Consolas', 'Andale Mono', 'Lucida Console', monospace;

// Text style
$base-font-size: 16px;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;

// Paragraph margins and such
$base-text-spacing: 24px;
$text-spacing: em($base-text-spacing);

// $text-spacing without 'em'
$base-line-height: $text-spacing / 1em;

// Heading sizes
$h1: 42px;
$h2: 32px;
$h3: 22px;
$h4: 18px;
$h5: $base-font-size;
$h6: $base-font-size;
