/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: em(50px);
  padding: 0 18px;
  border-radius: $form-control-radius;
  font-family: $font-stack-main;
  font-weight: $font-weight-regular;
  @include font-size($form-control-font-size);
  line-height: em(50px) - em(2px);
  text-decoration: none;
  text-align: center;
  text-shadow: none;
  vertical-align: baseline;
  box-shadow: none;
  transition: background-color 0.3s ease-out;

  &:focus {
    outline: 1px dotted $color-body-foreground;
  }
}

@mixin button-primary-colors {
  background-color: $color-action;
  color: #fff;
}
@mixin button-primary {
  @include button;
  @include button-primary-colors;

  &:hover,
  &:focus {
    background-color: lighten($color-action, 15%);
  }
  &[disabled],
  &.disabled {
    background-color: desaturate(lighten($color-action, 20%), 20%);
  }
}

@mixin button-danger-colors {
  background-color: $color-red;
  color: #fff;
}

@mixin button-danger {
  @include button;
  @include button-danger-colors;

  &:hover,
  &:focus {
    background-color: lighten($color-red, 15%);
  }
  &[disabled],
  &.disabled {
    background-color: desaturate(lighten($color-red, 25%), 20%);
  }
}

/*---------- Standard button classes ----------*/
.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  @include button-primary;

  &[disabled] {
    cursor: default;
  }
  > span {
    vertical-align: middle;
  }
}

a.btn--primary-colors,
.btn--primary-colors {
  @include button-primary-colors;
}

a.btn-danger,
.btn-danger {
  @include button-danger;
}
a.btn--danger-colors,
.btn--danger-colors {
  @include button-danger-colors;
}
