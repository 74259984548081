@mixin page-margin {
  padding-left: $page-margin;
  padding-right: $page-margin;

  @include media-max($breakpoint-page-margin-small) {
    padding-left: $page-margin / 2;
    padding-right: $page-margin / 2;
  }
}
%page-margin {
  @include page-margin;
}

@mixin wrap-base {
  @include page-margin;

  margin-left: auto;
  margin-right: auto;
}
%wrap-base {
  @include wrap-base;
}

@mixin wrap-width {
  max-width: $wrap-width + ($page-margin * 2);
}
@mixin wrap-width-thin {
  max-width: $wrap-width-thin + ($page-margin * 2);
}
@mixin wrap-width-wide {
  max-width: $wrap-width-wide + ($page-margin * 2);
}

%wrap-width {
  @include wrap-width;
}
%wrap-width-thin {
  @include wrap-width-thin;
}
%wrap-width-wide {
  @include wrap-width-wide;
}

@mixin wrap {
  @include wrap-base;
  @include wrap-width;
}
@mixin wrap-thin {
  @include wrap-base;
  @include wrap-width-thin;
}
@mixin wrap-wide {
  @include wrap-base;
  @include wrap-width-wide;
}

%wrap {
  @extend %wrap-base;
  @extend %wrap-width;
}
%wrap-thin {
  @extend %wrap-base;
  @extend %wrap-width-thin;
}
%wrap-wide {
  @extend %wrap-base;
  @extend %wrap-width-wide;
}

.wrap {
  @extend %wrap;
}
.wrap-thin {
  @extend %wrap-thin;
}
.wrap-wide {
  @extend %wrap-wide;
}

.wrap-content {
  margin: 80px 20px 0;
  min-height: 80vh;

  @include media-max($breakpoint-grid-tight) {
    margin: 40px 0;
  }
}