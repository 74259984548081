a:link,
a:visited {
  color: $color-action;
}
a:hover,
a:focus {
  text-decoration: none;
}

%overlay-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-indent: -999px;
  font-size: 12px;
  z-index: $z-overlay-link;

  /* A 10x10 transparent PNG; a fix for the link not being clickable in IE */
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABdJREFUeNpi/P//PwMxgHFUIX0VAgQYACK+He3OQHX/AAAAAElFTkSuQmCC') !important;
}
.overlay-link {
  @extend %overlay-link;
}

%base-link {
  text-decoration: none;
  color: $color-action;

  &:hover,
  &:focus {
    color: darken($color-action, 15%);
  }
}
