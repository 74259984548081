// Clear contained floats
@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
  }
}

// Font size in rem with px fallback
@mixin font-size($size) {
  @if unit($size) == 'em' {
    $size: px($size);
  }

  font-size: $size;
  font-size: rem($size);
}

// Screen reader accessible text, from HTML5 Boilerplate
@mixin visuallyhidden {
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
}
@mixin non-visuallyhidden {
  clip: auto;
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  white-space: inherit;
}

// Media queries
// Defaults to width, since it's far more common than height.
@mixin media-max($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin media-min($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin media-between($max, $min) {
  @media screen and (max-width: $max) and (min-width: $min) {
    @content;
  }
}
@mixin media-max-height($max) {
  @media screen and (max-height: $max) {
    @content;
  }
}
@mixin media-min-height($min) {
  @media screen and (min-height: $min) {
    @content;
  }
}
@mixin media-between-height($max, $min) {
  @media screen and (max-height: $max) and (min-height: $min) {
    @content;
  }
}
