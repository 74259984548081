// Utility/helper classes for various common uses

.clearfix {
  @include clearfix;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.visuallyhidden {
  @include visuallyhidden;
}
%non-visuallyhidden {
  @include non-visuallyhidden;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  @extend %non-visuallyhidden;
}
