// Some generic small-ish phone size
$preview-mobile-width: 350px;
$preview-mobile-height: 650px;

// The typical iPad size in landscape
$preview-tablet-width: 1024px;
$preview-tablet-height: 768px;

.preview-wrap {
  padding: 20px;
}

.preview-controls {
  text-align: center;

  label {
    margin: 0 10px;
  }
  input,
  span {
    vertical-align: middle;
  }
  input {
    margin-right: 5px;
  }
}

/*-------------------- The preview 'device' --------------------*/
.preview,
.preview-frame {
  transition: width 0.35s ease, height 0.35s ease, padding 0.35s ease;
}

// The device itself
.preview {
  position: relative;
  width: $preview-mobile-width + 20px;
  height: $preview-mobile-height + 60px;
  margin: 20px auto;
  padding: 30px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #e6e6e6;
}
.preview--mobile {
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    height: 4px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.15);
  }
  &::before {
    top: 13px;
    width: 50px;
    margin-left: -25px;
  }
  &::after {
    bottom: 13px;
    width: 100px;
    margin-left: -50px;
  }
}
.preview--tablet {
  width: $preview-tablet-width + 80px;
  height: $preview-tablet-height + 40px;
  padding: 20px 40px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 15px;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.15);
  }
}

// The device screen
.preview-frame {
  display: flex;
  flex-direction: column;
  width: $preview-mobile-width;
  height: $preview-mobile-height;
  border: 2px solid #000;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;

  .preview--tablet & {
    width: $preview-tablet-width;
    height: $preview-tablet-height;
  }
}

// Device app bar
.preview-app-bar {
  position: relative;
  padding: 30px 10px 10px;
  background: $color-red-pale;
  color: #fff;
  text-align: center;

  &::before,
  &::after {
    display: block;
    position: absolute;
  }
  &::before {
    content: '13:28';
    left: 50%;
    top: 3px;
    width: 40px;
    margin-left: -20px;
    font-size: 12px;
    text-align: center;
  }
  &::after {
    content: '100%';
    top: 4px;
    right: 35px;
    font-size: 10px;
    text-align: right;
  }
  .icon-battery {
    position: absolute;
    width: 21px;
    height: 16px;
    top: 4px;
    right: 8px;
  }
  .icon-wifi {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 3px;
    left: 7px;
  }
}
.preview-title {
  margin: 0;
  font-size: 22px;
}

// The page content excluding the app bar
.preview-body {
  flex-grow: 1;
  overflow: auto;
}

/*-------------------- Preview of type 'page' --------------------*/
.page-preview {
  font-size: 14px;

  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > div,
  > p {
    padding-left: 20px;
    padding-right: 20px;
  }
  > img {
    margin-bottom: 20px;
  }
  h2 {
    font-size: 20px;
  }
  h3,
  h4,
  h5,
  h6 {
    font-size: 16px;
  }
}

.link-action {
  a {
    @include button-primary;
    width: 100%;
  }
}

/*-------------------- Preview of type 'slider' --------------------*/
.slider-preview {
  padding: 20px 0;
}

.preview-slider {
  overflow: auto;
}

.preview-slider-inner {
  display: flex;
}
.preview-slide {
  position: relative;
  flex: 0 0 ($preview-mobile-width - 60px);
  // The bottom margin is for some extra scrollbar space
  margin: 0 5px 20px;
  transition: flex-basis 0.35s ease;

  .preview--tablet & {
    flex: 0 0 450px;
  }
}

.preview-slide-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 250px;
  padding: 15px 20px;
  color: #fff;
  transition: max-width 0.35s ease;

  .preview--tablet & {
    max-width: 320px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 14px;
    font-weight: $font-weight-regular;
  }
  p {
    margin: 0;
  }
}

// Overflow and padding don't go well together
.slider-padding-helper {
  flex: 0 0 15px;
}

/*-------------------- Preview of type 'heroheader' --------------------*/
.hero-header-preview {
  padding: 20px;
  min-height: 100%;
  background: darkgray;
  color: white;
  display: flex;
  flex-direction: column;
}

.hero-header-preview-header {
  flex: 1;
}

.hero-header-preview-logo {
  width: 120px;

  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;

  img {
    width: 120px;
    height: 57px;
  }
}
