$wrap-width: em(920px);
$wrap-width-thin: em(650px);
$wrap-width-wide: em(1050px);

$page-margin: em(50px);
$section-spacing: 35px;

$logo-height: 31px;
$logo-width: 210px;
$logo-height-small: 23px;
$logo-width-small: 160px;

$breakpoint-mobile-l: em(375px);

// Form elements
$form-control-font-size: 15px;
$form-control-radius: 3px;
$form-field-padding: em(5px, $form-control-font-size);
$form-control-height: em(30px, $form-control-font-size);

// Breakpoints
$breakpoint-full-wrap: em(1020px);

// Reduce text sizes
$breakpoint-type-medium: em(1200px);
$breakpoint-type-small: em(450px);

$breakpoint-grid-tight: em(700px);
$breakpoint-page-margin-small: em(550px);

$breakpoint-common-wide: em(1080px);
