// Color definitions
$color-gray-dark: #2b2b2b;
$color-gray-middle: #767676;
$color-gray-light: #f1f1f1;
$color-red: #db001b;
$color-red-pale: #bb4041;

// Semantic assignments. When possible and logical, use colors through more
// meaningful, "use case" names.
$color-body-foreground: $color-gray-dark;
$color-body-background: #fff;

// Disabled text, lightest accessible text color on white
$color-disabled: #767676;

// Brand things like selection color
$color-main: $color-red;

// Links and main buttons
$color-action: $color-gray-dark;
